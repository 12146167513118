<template>
  <div class="chart">
    <div class="item">
      <div id="cup">
      </div>
      <div class="num">
        <div><span>{{ list.detectionNumUp||0 }}</span>/{{ list.detectionNum }}</div>
        <div>杯具洗消昨日达标酒店数</div>
      </div>
    </div>
    <div class="item">
      <div id="ware"></div>
      <div class="num">
        <div><span style="color: #3AFFE4;">{{ list.sanitaryNumUp||0 }}</span>/{{ list.sanitaryNum||0 }}</div>
        <div>洁具清洁昨日达标酒店数</div>
      </div>
    </div>
    <div class="item">
      <div id="linen"></div>
      <div class="num">
        <div><span style="color: #E9A5FF;">{{ list.textileNumUp||0 }}</span>/{{ list.textileNum||0 }}</div>
        <div>布草更换昨日达标酒店数</div>
      </div>
    </div>
    

  </div>
</template>
<script>
import { getHotelWorkNum } from '@/api'
import { mapGetters } from 'vuex'


export default{
  data(){
    return{
      list:{},
      cupRate:null,
      linenRate:null,
      wareRate:null,

    }
  },
  computed:{
    ...mapGetters(['name'])

  },
  created(){
  },
  mounted(){
    this.init()

  },
  methods:{
    init(){
      getHotelWorkNum().then(res=>{
        // console.log(res);
        if (res.code==0&&res.message=='success'){ 
         const {detectionNumUp,detectionNum,sanitaryNumUp,sanitaryNum,textileNumUp,textileNum}=res.data
         this.list =res.data
         this.cupRate=isNaN((detectionNumUp/detectionNum)*100)?0:(detectionNumUp/detectionNum)*100
         this.wareRate=isNaN((sanitaryNumUp/sanitaryNum)*100)?0:(sanitaryNumUp/sanitaryNum)*100
         this.linenRate=isNaN((textileNumUp/textileNum)*100)?0:(textileNumUp/textileNum)*100
        console.log(isNaN(this.linenRate))
        }else{
          this.list={}
          this.cupRate=0
          this.wareRate=0
          this.linenRate=0
        }
        this.drawPie()
        this.drawPieLinen()
        this.drawPieWare()
      })
    },

    drawPie () {
      const chart = this.$echarts.init(document.getElementById('cup'))
      // 绘制图表
      const option = {
        title: [{
          text: `${this.cupRate.toFixed(2)}%`,
          x: '43%',
          y: '40%',
          textAlign: 'center',
          textStyle: {
            fontSize: '16',
            fontWeight: '400',
            color: '#FFFFFF',
            textAlign: 'center',
          },
        }],
        polar: {
          radius: ['95%', '75%'],
          center: ['52%', '50%'],
        },
        angleAxis: {
          max: 100,
          show: false,
        },
        radiusAxis: {
          type: 'category',
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,

          },
          axisTick: {
            show: false
          },
        },
        series: [
          {
            name: '',
            type: 'bar',
            roundCap: true,
            barWidth: 60,
            showBackground: true,
            backgroundStyle: {
              color: '#2e2856',
              opacity:"0.5"
            },
            data: [this.cupRate],
            coordinateSystem: 'polar',
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0.5, 1, 0.5, [{
                  offset: 0,
                  color: '#28D9FA'
                }, {
                  offset: 1,
                  color: '#28ADFA'
                }]),
              }
            }
          },

        ]
      };
      option && chart.setOption(option)
      window.addEventListener("resize", function () {
        chart.resize();
      })
    },
    drawPieLinen () {
      const chart = this.$echarts.init(document.getElementById('linen'))
      // 绘制图表
      const option = {
        title: [{
          text: `${this.linenRate.toFixed(2)}%`,
          x: '43%',
          y: '40%',
          textAlign: 'center',
          textStyle: {
            fontSize: '16',
            fontWeight: '400',
            color: '#FFFFFF',
            textAlign: 'center',
          },
        }],
        polar: {
          radius: ['95%', '75%'],
          center: ['52%', '50%'],
        },
        angleAxis: {
          max: 100,
          show: false,
        },
        radiusAxis: {
          type: 'category',
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,

          },
          axisTick: {
            show: false
          },
        },
        series: [
          {
            name: '',
            type: 'bar',
            roundCap: true,
            barWidth: 60,
            showBackground: true,
            backgroundStyle: {
              color: '#2e2856',
              opacity:"0.5"
            },
            data: [this.linenRate],
            coordinateSystem: 'polar',
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0.5, 1, 0.5, [{
                  offset: 0,
                  color: '#BB42EF'                 
                }, {
                  offset: 1,
                  color: '#E9A5FF'
                }]),
              }
            }
          },

        ]
      };
      option && chart.setOption(option)
      window.addEventListener("resize", function () {
        chart.resize();
      })
    },
    drawPieWare () {
      const chart = this.$echarts.init(document.getElementById('ware'))
      // 绘制图表
      const option = {
        title: [{
          text: `${this.wareRate.toFixed(2)}%`,
          x: '43%',
          y: '40%',
          textAlign: 'center',
          textStyle: {
            fontSize: '16',
            fontWeight: '400',
            color: '#FFFFFF',
            textAlign: 'center',
          },
        }],
        polar: {
          radius: ['95%', '75%'],
          center: ['52%', '50%'],
        },
        angleAxis: {
          max: 100,
          show: false,
        },
        radiusAxis: {
          type: 'category',
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,

          },
          axisTick: {
            show: false
          },
        },
        series: [
          {
            name: '',
            type: 'bar',
            roundCap: true,
            barWidth: 60,
            showBackground: true,
            backgroundStyle: {
              color: '#2e2856',
              opacity:"0.5"
            },
            data: [this.wareRate],
            coordinateSystem: 'polar',
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0.5, 1, 0.5, [{
                  offset: 0,
                  color: '#3AFFCA'                 
                }, {
                  offset: 1,
                  color: '#3AFFE4'
                }]),
              }
            }
          },
        ]
      };
      option && chart.setOption(option)
      window.addEventListener("resize", function () {
        chart.resize();
      })
    },


  }
}
</script>
<style lang="scss" scoped>
.chart{
  display: flex;
  justify-content: space-between;
  padding: 15px 40px;
  .item{
    font-family: SourceHanSansCNNormal;
    width: 33%;
    display: flex;
    flex-direction: column;
    justify-content:center ;
    align-items:center ;
    #cup ,#linen ,#ware{
    width: 125px;
    height: 125px;
    margin-bottom: 5px;
    }
    div{
      text-align: center;
      color: #FFFFFF;
      font-size: 15px;
      margin-top: 5px;
      span{
        color: #28D9FA;
      }
    }
  }
  
}
</style>