<template>
  <div class='right_bottom'>
    <div class="city_title"></div>
    <div class="content">
      <pia @data="data"/>
      <div class='workSum'>
        <p><span>风险推送事件：</span><span style="color: #44CAFF;">{{itemMsg.total}}</span> (个)</p>
        <p><span>转执法：</span><span style="color: #E9A5FF;">{{itemMsg.law}}</span> (个)</p>
        <p><span>解除风险：</span><span style="color:#3afce3;">{{itemMsg.relieve}}</span> (个)</p>
      </div>
    </div>
  </div>
</template>

<script>
import pia from './pia.vue'
export default {
  components:{pia},
  data () {
    return {
      itemMsg: {}
    };
  },
  created () {
  },
  mounted () {

  },
  methods: {
    data(val){
      this.itemMsg=val;

    }
    
  }
};
</script>

<style scoped lang="scss">
.right_bottom {
  margin-left: 24px;
  width: 607px;
  height: 307px;
  background: url("../../../../assets/city/rightbottom.png") no-repeat;
  background-size: 100% 100%;
  .city_title {
    background: url("../../../../assets/city/work_title.png") no-repeat;
    height: 33px;
    background-size: 100%;
  }
  .content{
    padding: 0 20px;
    display: flex;
    align-items: center;
  }
  .workSum {
    margin-top: 35px;
    p {
      font-size: 18px;
      color: #fdfdfd;
      text-align: center;
      margin-bottom: 30px;
      span:last-child {
        font-size: 30px;
        font-family: Furore;
      }
      span:first-child {
        display: inline-block;
        width: 128px;
        text-align: right;
      }
      &:nth-child(2){
        span:first-child::before{
          content: '';
          display: inline-block;
          width: 16px;
         height: 16px;
         background: #E9A5FF;
         margin-right: 10px;
        }
      }
      &:last-child{
        span:first-child::before{
          content: '';
          display: inline-block;
          width: 16px;
         height: 16px;
         background: #44CAFF;
         margin-right: 10px;

        }
      }
    }
  }
}
</style>
