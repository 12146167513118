<template>
  <div class='center_top'>
    <div class="box">
      <div class="card">
        酒店数量 <countTo :startVal='0' :endVal='count.siteCount' :duration='1000' separator="" />
      </div>
      <!-- <div class="card">
      在线率 <countTo :startVal='0' :endVal='count.onlineRate' :duration='1000' separator="" suffix="%" />
      </div> -->
      <div class="card">
        推送事件 <countTo :startVal='0' :endVal='count.riskTotalCount' :duration='1000' separator="" />
      </div>
    </div>
    <Map />
  </div>
</template>

<script>
import countTo from 'vue-count-to';
import { getCountStatistic } from '@/api'
import Map from './map.vue'
// import AMap from 'AMap'
import { mapGetters } from 'vuex'

export default {
  components: { countTo, Map },
  // components:{Map},
  data () {
    return {
      count: {
        siteCount:null,
        onlineSiteCount:null,
        riskTotalCount:null,
        onlineRate:null
      },
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['name'])
  },
  methods: {
    init () {
      getCountStatistic().then(res => {
        this.count = res.data
        // this.count.onlineRate=((res.data.onlineSiteCount/res.data.siteCount)*100).toFixed(2)
      })
    },
  }
};
</script>

<style scoped lang="scss">
.center_top {
  flex: 1;
  height: 629px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 24px 0;
  .box{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    .card{
      width:245px;
      height:70px;
      background: url('~@/assets/city/centerCard.png') no-repeat;
      background-size:100% 100%;
      color: #ffffff;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0 10px;
      span{
        font-size: 32px;
        font-weight: bold;
        color: #3AFFE4;
      }
    }
  }
  // .amount {
  //   > div {
  //     // position: relative;
  //     margin-top: 51px;
  //     div {
  //       width: 112px;
  //       height: 77px;
  //       background: url("../../../../assets/city/map_bg.png") no-repeat;
  //       background-size: 100% 100%;
  //       font-size: 36px;
  //       color: #3affe4;
  //       font-weight: 600;
  //       text-align: center;
  //       padding-top: 1px;
  //       > span {
  //         display: block;
  //         margin-top: 18px;
  //       }
  //     }

  //     p {
  //       width: 100%;
  //       font-size: 16px;
  //       color: #ffffff;
  //       // position: absolute;
  //       // margin: 0 auto;
  //       text-align: center;
  //       // bottom: 0;
  //     }
  //   }
  //   > div:first-child {
  //     margin-top: 15px;
  //   }
  // }
  // .amount:last-child {
  //   > div {
  //     div {
  //       font-size: 32px;
  //       white-space: nowrap;
  //       span {
  //         display: inline-block;
  //         margin-top: 18px;
  //         margin-left: -9px;
  //       }
  //       .countright {
  //         color: rgba(255, 255, 255, 0.5);
  //         font-size: 28px;
  //         margin-left: 0;
  //       }
  //     }
  //   }
  // }
}
</style>
