<template>
  <div class='right_top'>
    <div class="city_title cup"></div>
    <div class="baseNum">
      <div class="item">
        <img src="../../../../assets/newDistrict/room.png" alt="">
        <div class="itemText">
          <div>{{ itemMsg.roomSum}}</div>
          <div>房间数量</div>
        </div>
      </div>
      <div class="item">
        <img src="../../../../assets/newDistrict/ware.png" alt="">
        <div class="itemText">
          <div>{{ totalData.roomCount}}</div>
          <div>洗消间数量 </div>
        </div>
      </div>
      <div class="item">
        <img src="../../../../assets/newDistrict/device.png" alt="">
        <div class="itemText">
          <div>{{ itemMsg.sanitaryWareRfidDeviceSum}}</div>
          <div>读卡器数量</div>
        </div>
      </div>
      <div class="item">
        <img src="../../../../assets/newDistrict/camera.png" alt="">
        <div class="itemText">
          <div>{{ totalData.cameraCount}}</div>
          <div>摄像头数量</div>
        </div>
      </div>
      <div class="item">
        <img src="../../../../assets/newDistrict/linen.png" alt="">
        <div class="itemText">
          <div>{{ itemMsg.textileSum}}</div>
          <div>布草数量</div>
        </div>
      </div>
      <div class="item">
        <img src="../../../../assets/newDistrict/socket.png" alt="">
        <div class="itemText">
          <div>{{ totalData.boardCount}}</div>
          <div>插座数量</div>
        </div>
      </div>
    </div>
    <div class="lineBox"><div class="line"></div></div>
    <div class="straff">
      <img src="../../../../assets/city/straff.png" alt="">
      <div>保洁员数量：</div><div>{{ cleanerCount }}</div>
    </div>
    <div class="lineBox"><div class="line"></div></div>
    <div class="useNum">
      <el-row>
        <el-col :span="12">本周房间打扫数量：<span>{{itemMsg.braceletRoomSum  }}</span></el-col>
        <el-col :span="12">本周消毒柜时长(h)：<span>{{ bottomData.runtime ? parseInt(bottomData.runtime / 3600) : 0 }}</span></el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getRight, getCountStatistic, getCup,getCleaner } from '@/api'

export default {
  data () {
      return {
      itemMsg: {},
      cleanerCount:null,
      totalData: {
        areaCount: 0,
        boardCount: 0,
        cameraCount: 0,
        roomCount: 0,
        siteCount: 0,
        visitorCount: 0
      },
      bottomData: {}
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  methods: {
    init () {
      getRight().then(res => {
        this.itemMsg = res.data
        // console.log(res.data);
      })
      getCountStatistic().then(res => {
        // console.log(res);
        this.totalData = res.data
      })
      getCup().then(res => {
        this.bottomData = res.data
      })
      getCleaner().then(res=>{
        this.cleanerCount=res.data
      })
    }
  }
};
</script>

<style scoped lang="scss">
.right_top {
  width: 607px;
  height: 629px;
  background: url("../../../../assets/city/lefttop.png") no-repeat;
  background-size: 100% 100%;
  .cup {
    background: url("../../../../assets/city/cup_title.png") no-repeat;
    height: 33px;
    background-size: 100%;
  }

  .baseNum{
    padding: 20px  47px 0;
    color: #ffffff;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    flex-wrap: wrap;
    .item{
      width: 50%;
      display: flex;
      align-items: center;
      height:fit-content;
      font-size: 16px;
      font-weight: 400;
      img{
        width:90px;
        height: 85px;
        margin-right: 24px;
      }
      .itemText{
        div:first-child{
          font-size: 32px;
          font-weight: bold;
          margin-bottom: 15px;
         }
      }
      &:nth-child(3){
        margin: 36px 0;
      }
      &:nth-child(4){
        margin: 36px 0;
      }
    }
  }
  .lineBox{
    padding-left: 27px;
    .line{
      background:url('~@/assets/city/line.png') no-repeat;
      height: 25px;
      background-size:100%;
    }
  }
  .straff{
    padding:32px 47px 7px ;
    display: flex;
    align-items: center;
    img{
      width: 34px;
      height: 39px;
      margin-right: 27px;
    }
    div{
      font-size: 16px;
      color: #FDFDFD;
      &:last-child{
        font-size: 32px;
        font-weight: bold;
        color: #FFFFFF;
      }
    }
  }
  .useNum{
    color: #ffffff;
    padding: 0 47px;
    .el-row{
      font-size:16px;
      color: #FDFDFD;
      margin-top: 15px;
      .el-col-12{
        display: flex;
        align-items: center;
        span{
          font-family: Furore;
          font-size: 28px;
          color:#44CAFF ;
        }
      }
    }
    
  }
}
</style>
