<template>
  <div class='left_top'>
    <div class="city_title"></div>
    <div class="content">
      <charts />
      <div class="workList">
        <el-row class="citycaption">
          <el-col :span="12">酒店名称</el-col>
          <el-col :span="4">洗消达标</el-col>
          <el-col :span="4">清洁达标</el-col>
          <el-col :span="4">布草更换</el-col>
        </el-row>
        <div class="swiper swiper_list" v-if="list.length>0">
        <div class="swiper-wrapper">
          <el-row v-for="(el, index) in list" class="swiper-slide" :key="index">
            <el-col :span="12">{{ el.siteName }}</el-col>
            <el-col :span="4">{{ el.detectionRate?(el.detectionRate*100).toFixed(2)+'%':'-' }}</el-col>
            <el-col :span="4">{{ el.sanitaryRate?(el.sanitaryRate*100).toFixed(2)+'%':'-' }}</el-col>
            <el-col :span="4">{{ el.textileRate?(el.textileRate*100).toFixed(2)+'%':'-' }}</el-col>
          </el-row>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import charts from './charts.vue'
import { mapGetters } from 'vuex'
import { getHotelWorkList } from '@/api'
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
Swiper.use([Autoplay])
export default {
  components:{charts},
  data () {
    return {
      adcodeList: [],
      adcodeArr: [],
      list: []
    };
  },
  created () {
    this.init()

  },
  mounted () {
  },
  computed: {
    ...mapGetters(['name'])
  },
  methods: {
    init () {
      getHotelWorkList().then(res => {
        // console.log(res,'>>>LeftTop');
        if (res.code===0&&res.message==='success') {
          // console.log(res.data);
          this.list=res.data
          
        }
        this.initSwiper()
      })
    },
    initSwiper () {
      this.$nextTick(() => {
        if (this.list && this.list.length > 9) {
          new Swiper('.swiper_list', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500
          })
        }
        if (this.list && this.list.length <= 9) {
          new Swiper('.swiper_list', {
            direction: 'vertical',
            slidesPerView: 'auto',
            // loop: true,
            speed: 1500
          })
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
*{
  font-family: SourceHanSansCNNormal;
}
.left_top {
  width: 609px;
  height: 629px;
  background: url("../../../../assets/city/lefttop.png") no-repeat;
  background-size: 100% 100%;
  .city_title {
    background: url("../../../../assets/newDistrict/leftTop_Tit.png") no-repeat;
    height: 33px;
    background-size: 100% ;
  }
  .content{
    .workList{
      padding: 50px 24px 20px 24px;
      .citycaption{
        color:#28D4F5 ;
        font-size: 14px;
        padding: 0 10px;
      }
      .swiper_list {
        overflow: hidden;
        height: 270px;
        // padding-left: 32px;
        margin-top: 20px;
        .swiper-wrapper{
          flex-direction: column;
        }
        .swiper-slide {
          height: 30px;
          color: white;
          display: flex;
          align-items: center;
          font-size: 14px;
          padding: 0 10px;
          .el-col-8 {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .swiper-slide:nth-child(2n) {
          background: rgba(68, 202, 255, 0.4);
        }
      }
    }
  
  }
}
</style>
