<template>
  <div class='left_bottom'>
    <div class="city_title"></div>
    <div class="rateBox">
      <div class="left">
        <div class="left_Title">
          <el-tooltip class="tooltip" effect="dark" placement="top" popper-class="atooltip">
            <div slot="content" style="text-align: center;">
              自2024年7月15日起，累计数据<br />
              杯具洗消在线率：在线插座数总和 / 运行插座数总和<br />
              洁具清洁使用率：背夹上线数总和 / 背夹数总和</div>
            <span>综合考核率</span>
          </el-tooltip>
        </div>
        <div class="content">
          <div class="rate">
            <div>
              <p>{{ cupRate }}</p>
              <p>杯具洗消在线率</p>
            </div>

          </div>
          <div class="rate">
            <div>
              <p>{{ wareRate }}</p>
              <p>洁具清洁使用率</p>
            </div>

          </div>

        </div>
      </div>
      <div class="right">
        <div class="content">
          <el-carousel ref="carousel" :autoplay="autoplay" height="200px" :interval="5000" indicator-position="none"
            arrow="never">
            <el-carousel-item v-for="(item, index) in monthRate" :key="index">
              <div>
                <div class="title">
                  <div @click="lastData(item)"></div>
                  <div>
                    <el-tooltip class="tooltip" effect="dark" placement="top" popper-class="atooltip">
                      <div slot="content" style="text-align: center;">
                        本月累计数据<br />
                        杯具洗消在线率：本月在线插座数总和 / 本月运行插座数总和<br />
                        洁具清洁使用率：本月背夹上线数总和 / 本月背夹数总和
                      </div>
                      <span>{{ item.monthCN }}月考核率</span>
                    </el-tooltip>
                  </div>
                  <div @click="next(item)"></div>
                </div>
                <div @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
                  <div class="rateData">
                    <p>{{ item.cupRate || '-' }}</p>
                    <p>杯具洗消在线率</p>
                  </div>
                  <div class="rateData">
                    <p>{{ item.wareRate || '-' }}</p>
                    <p>洁具清洁使用率</p>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { getRate, getMonthRate, getAreaList } from '@/api'

export default {
  data() {
    return {
      areaCode: localStorage.getItem('areaCode'),
      wareRate: null,
      cupRate: null,
      monthSatrt: this.$moment().format('YYYY') == '2024' ? '2024-07-15' : this.$moment().format('YYYY-01-01'),
      monthRate: [''],
      autoplay: true,
      today: this.$moment().format('YYYY-MM-DD'),
    };
  },
  created() {
    this.getcode()

    // setTimeout(() => {
    //   if (!sessionStorage.getItem('reload')) {
    //     window.location.reload();
    //     sessionStorage.setItem('reload', 'reload')
    //   }
    // }, 800);
  },
  mounted() {

  },
  watch: {

  },
  methods: {
    async getcode() {
      await getAreaList().then(res => {
        localStorage.setItem('areaCode', res.data[0].adcode)
        this.areaCode = res.data[0].adcode
        this.init()
        this.getMonthData()
      })
    },
    async init() {
      await getRate([9, this.areaCode, '2024-07-15', this.today]).then(({ data: res }) => {
        this.wareRate = ((res.statRate) * 100).toFixed(0) + '%'
      })
      await getRate([7, this.areaCode, '2024-07-15', this.today]).then(({ data: res }) => {
        this.cupRate = ((res.statRate) * 100).toFixed(0) + '%'
      })
    },
    async getMonthData() {

      await getMonthRate([9, this.areaCode, this.monthSatrt, this.today]).then(({ data: res }) => {
        const dataarr = res.map(i => {
          i.monthCN = i.month.slice(5, 7).startsWith(0) == true ? i.month.slice(6, 7) : i.month.slice(5, 7)
          return i
        })
        getMonthRate([7, this.areaCode, this.monthSatrt, this.today]).then(({ data: res }) => {
          let arr = []
          if (res.length > dataarr.length) {
            arr = res.map(i => {
              const data = dataarr.find(e => e.month == i.month)
              if (data) {
                return {
                  cupRate: (i.statRate * 100).toFixed(0) + '%',
                  wareRate: (data.statRate * 100).toFixed(0) + '%',
                  month: data.month,
                  monthCN: data.monthCN
                }
              } else {
                return {
                  cupRate: (i.statRate * 100).toFixed(0) + '%',
                  wareRate: null,
                  month: i.month,
                  monthCN: i.month.slice(5, 7).startsWith(0) == true ? i.month.slice(6, 7) : i.month.slice(5, 7)
                }
              }
            })
          } else {
            arr = dataarr.map(i => {
              const data = res.find(e => e.month == i.month)
              if (data) {
                return {
                  cupRate: (data.statRate * 100).toFixed(0) + '%',
                  wareRate: (i.statRate * 100).toFixed(0) + '%',
                  month: i.month,
                  monthCN: i.monthCN
                }
              } else {
                return {
                  cupRate: null,
                  wareRate: (i.statRate * 100).toFixed(0) + '%',
                  month: i.month,
                  monthCN: i.monthCN
                }
              }
            })
          }
          const a = arr.sort((a, b) => {
            if (a.month === this.$moment().format('YYYY-MM')) {
              return -1; // 将本个月放在最前
            } else if (b.month === this.$moment().format('YYYY-MM')) {
              return 1; // 将本个月放在最前
            } else {
              return a.momentDate - b.momentDate; // 其他按月份排序
            }
          });
          this.$set(this, 'monthRate', a);
          // this.monthRate = a
          // this.$forceUpdate()

        })

      })

    },
    handleMouseEnter() {
      this.$nextTick(() => {
        this.autoplay = false
      })
    },
    handleMouseLeave() {
      this.$nextTick(() => {
        this.autoplay = true
      })
    },
    lastData(item) {
      const index = this.monthRate.findIndex(rateItem => rateItem.month === (this.$moment(item.month).subtract(1, 'months').format('YYYY-MM')));
      if (index !== -1) {
        this.$refs.carousel.setActiveItem(index);
      } else {
        this.$refs.carousel.setActiveItem(this.monthRate.length - 1);
      }
    },
    next(item) {
      const index = this.monthRate.findIndex(rateItem => rateItem.month === (this.$moment(item.month).add(1, 'months').format('YYYY-MM')));
      const minMonthIndex = this.monthRate.reduce((minIndex, currentItem, currentIndex) => {
        const currentMonth = this.$moment(currentItem.month);
        const minMonth = this.$moment(this.monthRate[minIndex].month);
        if (currentMonth.isBefore(minMonth)) {
          return currentIndex;
        } else {
          return minIndex;
        }
      }, 0);

      // console.log("month 最小的索引为: ", minMonthIndex);
      if (index !== -1) {
        this.$refs.carousel.setActiveItem(index);
      } else {
        this.$refs.carousel.setActiveItem(minMonthIndex);
      }
    }

  }
};
</script>
<style>
.atooltip {
  /* background: #FFFFFF !important; */
  font-size: 18px;
}
</style>

<style scoped lang="scss">
.left_bottom {
  width: 609px;
  height: 307px;
  background: url("../../../../assets/city/leftbottom.png") no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  margin-right: 24px;

  .city_title {
    background: url("../../../../assets/city/onlineRateTit.png") no-repeat;
    height: 33px;
    background-size: 100%;
  }

  .rateBox {
    padding: 24px;
    display: flex;
    justify-content: space-between;

    .left {
      width: 291px;
      height: 221px;
      background: url("../../../../assets/city/onlineRateLeft.png") no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      position: relative;

      .left_Title {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
        color: transparent;
        background-image: linear-gradient(90deg, #44CAFF 0%, #CAF6FF 50%);
        background-clip: text;
        background-image: -webkit-linear-gradient(90deg, #44CAFF 0%, #CAF6FF 50%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        .el-tooltip {
          color: transparent;
          background-color: transparent;
          font-size: 20px;
          font-weight: 500;
        }
      }

      .content {
        padding: 25px 10px;

        .rate {
          display: flex;
          align-items: center;

          p:first-child {
            font-weight: bold;
            font-size: 32px;
            color: #3AFFE4;
            margin-bottom: 15px
          }

          p:last-child {
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
          }

          &::before {
            content: '';
            display: inline-block;
            width: 104px;
            height: 76px;
            background: url('../../../../assets/city/rateCup.png') no-repeat;
            background-size: cover;
            margin-right: 10px;
          }
        }

        .rate:last-child {
          margin-top: 20px
        }

        .rate:last-child::before {
          content: '';
          display: inline-block;
          width: 104px;
          height: 74px;
          background-size: cover;
          margin-right: 10px;
          background: url('../../../../assets/city/rateWare.png') no-repeat;
        }
      }
    }

    .right {
      width: 244px;
      height: 221px;
      background: url("../../../../assets/city/onlineRateRight.png") no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;

      .content {
        padding: 15px;
        color: #FFFFFF;

        ::v-deep .el-carousel {
          .el-carousel__arrow {
            top: 15px;
            transform: translateY(-70%);
            font-size: 0
          }

          .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 10px;
            margin-bottom: 30px;

            div:first-child {
              background: url('../../../../assets/city/arrowLeft.png') no-repeat;
              width: 18px;
              height: 20px;
              background-size: cover;
            }

            div:nth-child(2) {
              line-height: 1;
              text-align: center;
              font-size: 20px;
              color: transparent;
              background-image: linear-gradient(90deg, #44CAFF 0%, #CAF6FF 50%);
              background-clip: text;
              font-weight: 500;
              background-image: -webkit-linear-gradient(90deg, #44CAFF 0%, #CAF6FF 50%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              span {
                color: transparent;
                background-color: transparent;
              }
            }

            div:last-child {
              background: url('../../../../assets/city/arrowRight.png') no-repeat;
              width: 18px;
              height: 20px;
              background-size: cover;
            }
          }

          .rateData {
            margin-bottom: 30px;

            p {
              text-align: center;
              font-weight: 500;
              font-size: 24px;
              color: #3AFFE4;
              line-height: 1;
            }

            p:last-child {
              font-weight: 400;
              font-size: 14px;
              color: #FFFFFF;
              line-height: 1;
              margin-top: 14px;

            }
          }

        }
      }
    }
  }

}
</style>